export const books = [
    {
        "id": 1,
        "name": "الرياضيات",
        "image": "http://emmobank.com/assets/img/application/subjects/mathematics.svg",
        "division": "",
        "school": "all",
        "description": "",
        "sort": 1,
        "date": "2023/1/01",
        "active": true,
        "grade": "7",
        "gradeId":7,
        "count": 5
    }
]